.services__container {
  grid-template-columns: repeat(2, 270px);
  justify-content: center;
  column-gap: 3rem;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.services__content {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--container-color);
  padding: 6rem 0 2rem 2.5rem;
  transition: 0.3s;
  width: 270px;
}

.services__content:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.services__icon {
  display: block;
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: var(--mb-1);
}

.services__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
}

.services__button {
  color: var(--title-color);
  font-size: var(--small-font-size);
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  cursor: pointer;
}

.services__button-icon {
  font-size: 1rem;
  transition: 0.2s;
}

.services__button-icon:hover {
  transform: translateX(00.25rem);
}

.services__modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.services__modal-content {
  width: 500px;
  position: relative;
  background-color: var(--container-color);
  padding: 4.5rem 2.5rem 2.5rem;
  border-radius: 1.5rem;
}

.services__modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.services__modal-title,
.services__modal-description {
  text-align: center;
}

.services__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-2);
}

.services__modal-services {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
}

.services__modal-service {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.services__modal-icon {
  color: var(--title-color);
  font-size: 1.1rem;
}

.services__modal-info {
  font-size: var(--small-font-size);
}

/* Active Modal */
.active-modal {
  opacity: 1;
  visibility: visible;
}

/*========== BREAKPOINTS ==========*/
/* Large Devices */
@media screen and (max-width: 992px) {
  .services__container {
    grid-template-columns: repeat(2, 250px);
  }

  .services__content {
    width: 250px;
    padding: 4.5rem 0 2rem 2rem;
  }
}

/* Medium Devices */
@media screen and (max-width: 768px) {
  .services__container {
    grid-template-columns: repeat(2, 200px);
  }

  .services__content {
    width: 200px;
    padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  }
}

/* Small Tablets and Large Phones */
@media screen and (max-width: 576px) {
  .services__container {
    grid-template-columns: repeat(1, 270px);
  }

  .services__content {
    width: 270px;
  }
}

/* Small Devices */
@media screen and (max-width: 350px) {
  .services__container {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }

  .services__content {
    width: 100%;
    padding: 3.5rem 1rem 1.25rem 1.5rem;
  }
}
