.qualification__container {
  max-width: 768px;
  text-align: center;
  margin: 0 auto; /* Center the container */
  padding: 0 1rem; /* Add padding to prevent content from touching the edges */
}

.qualification__tab {
  display: flex;
  justify-content: center;
  margin-bottom: var(--mb-2);
}

.qualification__button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  color: var(--title-color);
  margin: 0 var(--mb-1);
  cursor: pointer;
}

.qualification__button:hover {
  color: var(--title-color-dark);
}

.qualification__icon {
  font-size: 1.8rem;
  margin-right: var(--mb-0-25);
}

.qualification__active {
  color: var(--title-color-dark);
}

.qualification__sections {
  display: grid;
  gap: 0.5rem;
  justify-content: center;
  align-items: center; /* Center items vertically */
}

.qualification__content {
  display: none;
}

.qualification__content-active {
  display: block;
}

.qualification__data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  gap: 1.5rem;
  position: relative;
  padding: 0.5rem 0;
  justify-items: center; /* Center items horizontally */
}

.qualification__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.qualification__subtitle {
  display: inline-block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1);
}

.qualification__calander {
  font-size: var(--small-font-size);
}

.qualification__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--text-color);
  border-radius: 50%;
}

.qualification__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--text-color);
  transform: translate(6px, -7px);
}

/*========== BREAKPOINTS ==========*/
/*Large Devices*/
@media screen and (max-width: 992px) {
  .qualification__container {
    margin-left: auto;
    margin-right: auto;
  }
}

/*Medium Devices*/
@media screen and (max-width: 768px) {
  .qualification__container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
}
@media screen and (max-width: 576px) {
  .qualification__sections {
    grid-template-columns: initial;
  }

  .qualification__button {
    margin: 0 var(--mb-0-25);
  }
}

/*Small Devices*/
@media screen and (max-width: 350px) {
}
