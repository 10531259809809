.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.work__item {
  cursor: pointer;
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.work__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
}

.work__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.work__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work__img {
  max-width: 259px;
  max-height: 350px;
  width: auto;
  height: auto;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.work__button {
  color: var(--text-color);
  background-color: var(--container-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  border-radius: 2px;
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

/*Active*/
.active__work {
  background-color: var(--title-color);
  color: var(--container-color);
}

/*========== BREAKPOINTS ==========*/
/*Large Devices*/
@media screen and (max-width: 992px) {
  .work__container {
    gap: 1.25rem;
  }

  .work__card {
    padding: 1rem;
  }

  .work__img {
    margin-bottom: 0.75rem;
  }

  .work__title {
    margin-bottom: 0.25rem;
  }
}

/*Medium Devices*/
@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: max-content;
  }
}

/*Small Devices*/
@media screen and (max-width: 350px) {
  .work__item {
    font-size: var(--small-font-size);
  }

  .work__filters {
    column-gap: 0.25rem;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content-img {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  max-height: 300px;
  width: auto;
  height: auto;
}

.modal-img {
  width: auto; /* Set width to auto */
  height: auto; /* Set height to auto */
  max-width: 500px; /* Increased max-width */
  max-height: 500px; /* Increased max-height */
  border-radius: 10px;
  margin-bottom: 20px;
}

.close {
  color: #aaa;
  align-self: flex-end;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.project-description {
  font-size: 11px;
  text-align: center;
}

.tech-stack {
  margin-top: 1rem;
  text-align: center;
}

.tech-stack h4 {
  margin-bottom: 0.5rem;
}

.tech-stack ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tech-stack li {
  background-color: rgb(35, 30, 100);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin: 0.25rem;
}

@media screen and (max-width: 576px) {
  .work__container {
    grid-template-columns: 1fr;
  }
  .work__img {
    max-width: 200px;
    max-height: 300px;
    height: auto;
    width: auto;
  }
  .modal-img {
    max-width: 200px; /* Set max-width to 200px */
    max-height: 300px; /* Adjust max-height accordingly */
  }
}
