.scroll-down {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  text-decoration: none;
}

.scroll-down__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--text-color);
  text-decoration: none;
  color: rgb(35, 30, 100);
}

.scroll-down__link:hover {
  color: var(--title-color-dark);
  text-decoration: none;
}

.scroll-down__text {
  text-decoration: none;
  margin-top: 0.5rem;
  font-size: var(--small-font-size);
  color: rgb(35, 30, 100);
}

.home__scroll-name {
  text-decoration: none;
}

.home__scroll-name.no-underline {
  text-decoration: none;
}

.home__scroll-button {
  text-decoration: none;
  color: rgb(35, 30, 100);
}

.home__scroll-button.no-underline {
  text-decoration: none;
}

.no-underline {
  text-decoration: none !important;
}
