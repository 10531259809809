.footer {
  background-color: var(--container-color);
  border-color: 1px solid rgba(0, 0, 0, 0.1);
}

.footer__container {
  padding: 2rem 0 6rem;
}

.footer__title,
.footer__link {
  color: var(--title-color);
  text-decoration: none;
}

.footer__title {
  text-align: center;
  margin-bottom: var(--mb-2);
}

.footer__link:hover {
  color: var(--title-color-dark);
}

.footer__list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: var(--mb-2);
}

.footer__social {
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;
}

.footer__social-link {
  background-color: var(--title-color);
  color: var(--container-color);
  font-size: 1.125rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  display: inline-flex;
  text-decoration: none;
}

.footer__social-link:hover {
  background-color: var(grey);
}

.footer__copy {
  display: block;
  margin-top: 4.5rem;
  color: var(--title-color-dark);
  text-align: center;
  font-size: var(--small-font-size);
}

/*For Large Devices*/
@media screen and (max-width: 992px) {
  .footer__social {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
}
